<template>
    <v-card v-if="permisos && permisos.includes('FUN_RETOMAR_POLIZA_VENTA_NO_EMITIDA')">
        <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">COTIZACIONES/POLIZAS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark color="primary" @click="open" small v-if="(view == 'agente' || permisos.includes('FUN_RETOMAR_POLIZA_VENTA_NO_EMITIDA')) && !restringirVenta && checkNueva">
                Crear
            </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-data-table :headers="columns" :items="polizas" item-key="name" class="elevation-1" loading-text="Cargando..."
            dense :items-per-page="5">

            <template #[`item.status`]="{ item }">
                {{ checkStatus(item.status) }}
            </template>

            <template #[`item.poliza`]="{ item }">
                {{ item.poliza ? item.poliza : "No disponible" }}
            </template>

            <template #[`item.productoId`]="{ item }">
                {{ obtenerNombreProducto(item.productoId) }}
            </template>

            <template v-slot:[`item.accion`]="{ item }">
                <v-btn icon @click="$router.push('/emision/' + item.id)">
                    <v-icon small class="mr-2">mdi-eye</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <FormPolizaDialog v-if="dialog" @updateEmisiones="getPolizas" :poliza="current_poliza"
            :identificador="identificador" :producto="producto" :productoAseguradora="productoAseguradora"
            :active.sync="dialog" :corte="this.corte" />
    </v-card>
</template>

<script>
import FormPolizaDialog from "./documentos/FormPolizaDialog.vue";
import { mapState, mapActions } from 'vuex'
// import { mainAxios } from "@/mainAxios";

export default {
    name: 'leads.detalles.Polizas',
    components: {
        FormPolizaDialog,
    },
    props: {
        identificador: {
            type: Number,
            required: true
        },
        producto: {
            type: String,
            required: true
        },
        productoAseguradora: {
            type: String,
            required: true
        },
        corte: {
            type: String,
            required: true
        },
        restringirVenta: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            dialog: false,
            agentes: [],
            // productos: [],
            view: 'admin',
            roles: JSON.parse(localStorage.getItem("roles")),
            columns: [
                { text: "Certificado", align: "start", sortable: false, value: "poliza" },
                { text: "Cliente", value: "cliente" },
                { text: "Estatus", value: "status" },
                { text: "Plan", value: "productoId" },
                { text: "ver", value: "accion" },
            ],
            agenteAccessToken: localStorage.getItem('agenteAccessToken'),
            current_poliza: null,
            checkNueva: true

        }
    }, mounted() {
        this.getRole();
        this.getAgentes();
        this.getPolizas(this.identificador);
    }, computed: {
        ...mapState('permisos', ['permisos']),
        ...mapState('polizas', ['polizas', 'productos'])
    }, methods: {
        ...mapActions("polizas", ['getPolizas']),

        obtenerNombreAgente(id) {
            if (id == 'No Disponible') {
                return id;
            }
            else {
                let agente = this.agentes.find(element => element.id == id);
                return agente.nombreCompleto;
            }
        },
        checkStatus(status){
            console.log("si entra: ", status)
            if(status == 'Nueva'){
                this.checkNueva = false
            }
            return status;
        },
        obtenerNombreProducto(id) {
            if (id == 'No Disponible') {
                return id;
            } else {
                let producto = this.productos.find(element => element.id == id);
                if (producto) {
                    return producto.nombre;
                } else {
                    "No disponible"
                }
            }
        },
        getRole() {
            var roles = []
            this.roles.forEach((element) => {
                roles.push(element.rol)
            });
            const rolesAdmin = ["ADMIN", "ADMINCC", 'MANAGERCC', 'DIRECTOR', 'FINANZAS'];
            const rolesAgents = ["AGENTCC", "OPERADOR", "OPERACIONES"];
            roles.forEach((element) => {
                if (rolesAdmin.includes(element)) {
                    this.view = 'admin';
                }
                if (rolesAgents.includes(element)) {
                    this.view = 'agente';
                }
            });
        },
        getAgentes() {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.agenteAccessToken,
                },
            };
            this.$axios
                .get(`/v1/agente/list`, config)
                .then((response) => {
                    this.agentes = response.data
                });
        },

        // obtenerProductos() {
        //     let config = {
        //         headers: {
        //             Authorization: 'Bearer ' + localStorage.agenteAccessToken,
        //         },
        //     };
        //     this.$axios
        //         .get('/v1/productos/listaProductos', config)
        //         .then((response) => {
        //             response.data.map(element => {
        //                 this.productos.push({
        //                     id: element.id,
        //                     nombre: element.nombre,
        //                     titulo: `${element.ramo.toUpperCase()} / ${element.nombre.toUpperCase()} / ${element.nombreAseguradora.toUpperCase()}`,
        //                     ramo: element.ramo,
        //                     compania: element.nombreAseguradora.toUpperCase(),
        //                     fechaCreacion: element.fechaCreacion.slice(0, 10),
        //                     fechaModificacion: element.fechaModificacion ? element.fechaModificacion : 'No Disponible',
        //                     check: false
        //                 })
        //             })
        //         })
        // },

        open() {
            this.dialog = true;
        },

        openPoliza(item) {
            window.open(`/emision/${item.id}`)
        }
    }
}
</script>