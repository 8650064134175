<template>
  <v-app>
    <v-main class="mb-15">
      <v-container fluid>
        <v-row>
          <v-col cols="6" style="text-align: center">
            <v-credit-card :trans="translations" @change="creditInfoChanged" />
            <img src="../../assets/images/comodo.png" width="200px" />
            <img src="../../assets/images/cards.png" width="200px" />
          </v-col>
          <v-col cols="6">
            <v-card style="padding: 30px">
              <img src="../../assets/intermx-1.svg" style="width: 150px" />
              <v-card-title primary-title>
                <div>
                  <h3
                    class="headline mb-0"
                    style="margin-bottom: 30px !important"
                  >
                    Tus datos de emisión
                  </h3>
                </div>
              </v-card-title>
              <div>
                <v-row>
                  <v-col cols="12">
                    <p>
                      Por favor revisa que todos tus datos sean correctos, en
                      caso de que alguno esté incorrecto por favor contacta a tu
                      agente.
                    </p>
                    <h3>Datos del cliente</h3>
                    Nombre: <b>{{ request.nombre }}</b
                    ><br />
                    Apellido Paterno: <b>{{ request.apellidoPaterno }}</b
                    ><br />
                    Apellido Materno: <b>{{ request.apellidoMaterno }}</b
                    ><br />
                    Código Postal: <b>{{ request.cp }}</b
                    ><br />
                    Edad: <b>{{ request.edad }}</b
                    ><br />
                    RFC: <b>{{ request.rfc }}</b
                    ><br />
                    Código Postal: <b>{{ request.cp }}</b
                    ><br />
                    Correo: <b>{{ request.correo }}</b
                    ><br />

                    <h3 style="margin-top: 20px">Datos del vehículo</h3>
                    <b>{{ parse(preEmision.vehiculoJson).vehiculo.nombre }}</b
                    ><br />
                    Modelo: <b>{{ parse(preEmision.vehiculoJson).modelo.id }}</b
                    ><br />
                    Serie: <b>{{ request['vehiculo-usuario'].serie }}</b
                    ><br />
                    Motor: <b>{{ request['vehiculo-usuario'].motor }}</b
                    ><br />
                    Placa: <b>{{ request['vehiculo-usuario'].placa }}</b
                    ><br />
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import 'v-credit-card/dist/VCreditCard.css';

export default {
  data() {
    return {
      preEmision: {},
      request: {},
      cotizacion: {},
    };
  },
  mounted() {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.agenteAccessToken,
      },
    };
    this.$axios
      .get('/v1/agente/emision/' + this.$route.params.uuid, config)
      .then((response) => {
        this.preEmision = response.data;
        this.request = this.parseRequest(this.preEmision.requestJson);

        this.$axios
          .get('/v1/cotizacion/uuid/' + this.preEmision.cotizacion, config)
          .then((value) => {
            this.cotizacion = value.data;
          });
      });
  },
  methods: {
    parseRequest() {
      return JSON.parse(this.preEmision.requestJson);
    },
    parse(value) {
      return JSON.parse(value);
    },
  },
};
</script>
