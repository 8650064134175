<template>
  <div>
    <v-card color="grey lighten-3" tile elevation="0">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-subheader class="ma-0 pa-0 text-title">
              Declaración de salud
            </v-subheader>
          </v-col>
          <v-col cols="12">
            <p>
              Declaro que en los últimos 3 años he estado en buen estado de salud y que no me encuentro en un proceso de
              invalidez o alguna enfermedad en fase terminal, ni padezco alguna de las siguientes enfermedades:
            </p>
            <ul>
              <li>ataque al corazón</li>
              <li>cáncer</li>
              <li>enfermedad pulmonar crónica</li>
              <li>enfisema</li>
              <li>cirrosis</li>
            </ul>
            <br>
            <p>
              Declaro que la información proporcionada es completa y correcta, y me comprometo a actualizar algún
              cambio.
            </p>
          </v-col>
          <v-col cols="12" class="pl-4">
            <v-radio-group v-model="declaracionAccepted" :column="false">
              <v-radio label="Sí" :value="true"></v-radio>
              <v-radio label="No" :value="false" class="ml-3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    accepted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      declaracionAccepted: this.accepted,
    };
  },

  watch: {
    declaracionAccepted(v) {
      this.$emit("change", v);
    },
  },
};
</script>
