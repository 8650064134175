import Vue from "vue";
import Vuex from "vuex";

// modules
import polizas from "./polizas/store";
import llamadas from "./llamadas/store";
import leads from "./leads/store";
import permisos from "./permisos/store";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        polizas, llamadas, leads, permisos
    }
});