export default {
    namespaced: true, 
    state: {
      permisos: []
    },
    mutations: {
      setPermisos(state, permisos) {
        state.permisos = permisos; 
    }
    },
    actions: {
      cargarPermisos({ commit }) {
        const permisos = JSON.parse(localStorage.getItem("permisos"))?.permisos || [];
        commit("setPermisos", permisos);
      }
    }
  };
  