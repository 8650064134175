<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <transition name="fade">
        <v-row class="mb-15 fill-height" align="center" justify="center">
          <v-carousel v-model="banner">
            <v-carousel-item v-for="banner in banners" :key="banner.id">
              <v-sheet height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <img :src="'img/bannersHome/' + banner.url" class="bannerHome" alt="" />
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>

          <template v-for="(item, i) in items">
            <v-col :key="i" cols="12" md="4" v-if="!item.enabled" style="height: 250px">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover } + ' flex-grow-1'"
                  style="height: 100%">
                  <v-card-title class="title white--text">
                    <v-row class="fill-height flex-column" justify="space-between">
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="8">
                            <p class="mt-4 subheading text-left" style="margin-left: 10px; color: black">
                              {{ item.title }}
                            </p>
                            <p class="mt-4 subheading text-left" style="margin-left: 10px; color: black">
                              Próximamente
                            </p>
                          </v-col>
                          <v-col cols="4">
                            <img :src="item.img" style="max-width: 100%; max-height: 100px" />
                          </v-col>
                        </v-row>

                        <div>
                          <p class="ma-0 body-1 font-weight-bold font-italic text-left" style="
                                    margin-left: 10px !important;
                                    color: black;
                                    margin-bottom: 15px !important;
                                  ">
                            {{ item.text }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
            <v-col :key="i" cols="12" md="4" v-if="item.enabled" v-on:click="$router.push({ path: item.route })"
              style="height: 250px; cursor: pointer">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover } + ' flex-grow-1'"
                  style="height: 100%">
                  <v-card-title class="title white--text">
                    <v-row class="fill-height flex-column" justify="space-between">
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="8">
                            <p class="mt-4 subheading text-left" style="margin-left: 10px; color: black">
                              {{ item.title }}
                            </p>
                          </v-col>
                          <v-col cols="4">
                            <img :src="item.img" style="max-width: 100%; max-height: 100px" />
                          </v-col>
                        </v-row>

                        <div>
                          <p class="ma-0 body-1 font-weight-bold font-italic text-left" style="
                                    margin-left: 10px !important;
                                    color: black;
                                    margin-bottom: 15px !important;
                                  ">
                            {{ item.text }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </v-row>
      </transition>
    </v-col>
  </v-row>
</template>

<script>
export default {

  data() {
    return {
      banner: 0,
      banners: [
        { id: 1, url: 'SEGURIFY_PortalAgentes_004_promocion.jpg' },
        { id: 2, url: 'SEGURIFY_PortalAgentes_005_Qualitas.jpg' },
        { id: 3, url: 'SEGURIFY_PortalAgentes_006_AIG.jpg' },
        { id: 4, url: 'SEGURIFY_PortalAgentes_007_GNP.jpg' },
        { id: 5, url: 'SEGURIFY_PortalAgentes_008_HDI.jpg' },
        { id: 6, url: 'SEGURIFY_PortalAgentes_009_ANA.jpg' },
        { id: 7, url: 'SEGURIFY_promo_AIG_05:07:21.jpg' },
      ],
      icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
      items: [
        {
          title: 'Segurify Comparador',
          text:
            'Segurify: ¡Rápido y fácil! Compara costos y beneficios entre nuestras aseguradoras.',
          route: 'cotiza/segurify',
          img: require('../../assets/intermx-1.svg'),
          enabled: true,
        },
        {
          title: 'Qualitas',
          text: 'Más de 26 años de experiencia a tu alcance. ',
          route: 'cotiza/qualitas',
          img: require('../../assets/qualitas_new.svg'),
          enabled: true,
        },
        {
          title: 'GNP',
          text:
            'El respaldo de una empresa 100% mexicana, comprometida con brindar la mejor protección a sus asegurados.',
          route: 'cotiza/gnp',
          img: require('../../assets/gnp_new.svg'),
          enabled: true,
        },
        {
          title: 'AIG',
          text:
            'La mejor experiencia de servicio al alcance de todos sus asegurados. ',
          route: 'cotiza/aig',
          img: require('../../assets/aig_new_two.png'),
          enabled: true,
        },
        {
          title: 'Ana',
          text: 'Eficiencia y calidad para sus clientes. ',
          route: 'cotiza/ana',
          img: require('../../assets/ana.png'),
          enabled: false,
        },
        {
          title: 'HDI',
          text: 'Innovación para tu protección',
          route: 'cotiza/hdi',
          img: require('../../assets/hdi_new.svg'),
          enabled: true,
        },
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    };
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

/* .v-card:not(.on-hover) {
  opacity: 0.6;
} */

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
