<template>
  <div>
    <v-snackbar v-model="snackbar" timeout="5000" top>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-model="drawer"
      permanent
      app
      expand-on-hover
      color="#282828"
      :dark="true"
    >
      <v-list-item class="px-2">
        <v-list-item-title
          >{{ name }}
          <br />
          <span class="rolNota">{{
            rol == "ADMIN"
              ? "Admin"
              : rol == "ADMINAGENT"
              ? "Admin Agente"
              : rol == "MANAGER"
              ? "Manager Agente"
              : rol == "AGENT"
              ? "Agente"
              : rol == "ADMINCC"
              ? "Admin Call Center"
              : rol == "MANAGERCC"
              ? "Manager Call Center"
              : rol == "AGENTCC"
              ? "Agente Call Center"
              : rol == "DIGITAL"
              ? "Digital"
              : rol == "DIRECTOR"
              ? "Director"
              : rol == "DIRECTO"
              ? "Directo"
              : rol == "FINANZAS"
              ? "Finanzas"
              : rol == "MESADECONTROL"
              ? "Mesa de Control"
              : rol == "OPERACIONES"
              ? "Operaciones"
              : rol == "OPERADOR"
              ? "Operador"
              : rol == "AGENTPARTNER"
              ? "Agente Partner"
              : rol == "AUDITOR"
              ? "Gestor de auditoría"
              : rol == "SUPASISTENCIAS"
              ? "Asistencias"
              : rol == "ASISTENCIADORA"
              ? "Asistencias"
              : ""
          }}</span>
        </v-list-item-title>
      </v-list-item>

      <div>
        <v-divider></v-divider>
        <v-list dense v-for="(list, idx) in listaDesplegable" :key="idx">
          <v-list-group
            v-for="item in list.items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              v-on:click="$router.push(child.route)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </div>

      <v-divider></v-divider>
      <v-list dense style="cursor: pointer">
        <v-list-item v-on:click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getPermisos } from '../services/permisosService';
export default {
  data() {
    return {
      text: null,
      snackbar: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      name: localStorage.agenteName,
      drawer: true,
      listaDesplegable: null,
    };
  },
  created() {
    this.$emmited.$on("notify", (value) => {
      console.log(value);
      this.snackbar = value.open;
      this.text = value.text;
    });
  },
  mounted() {
    this.setListaDesplegable();
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },

    async setListaDesplegable() {
      await this.checkPermisos();
      const permisos = JSON.parse(localStorage.getItem("permisos")).permisos;
      const permisos_key = permisos.map(e => e.key_name);
      this.$store.commit('permisos/setPermisos', permisos_key);
      const nav = [
            {
              title: "Cuenta",
              items: [
                {
                  action: "mdi-account-circle",
                  active: true,
                  title: "Operación",
                  items: [
                    {
                      title: "Leads",
                      key: "NAV_LEADS",
                      route: "/bradescard",
                    },
                    {
                      title: "Leads Inbound",
                      key: "NAV_LEADS_INBOUND",
                      route: "/bradescard/leads/dashboard",
                    },
                    {
                      title: "Clientes",
                      key: "NAV_CLIENTES",
                      route: "/lista-clientes",
                    },
                    {
                      title: "Pólizas",
                      key: "NAV_POLIZAS",
                      route: "/mis-emisiones",
                    },
                    {
                      title: "Solicitudes",
                      key: "NAV_SOLICITUDES",
                      route: "/mis-solicitudes",
                    },
                    // {
                    //   title: "Cuotas de Pólizas",
                    //   route: "/cuotas-hub",
                    //   //route: '/proximamente',
                    // },
                    // {
                    //   title: "Productos",
                    //   route: "/lista-productos",
                    // },
                  ],
                },
                {
                  action: "mdi-account-box-multiple-outline",
                  title: "Administración",
                  items: [
                    {
                      title: "Usuarios",
                      key: "NAV_USUARIOS",
                      route: "/usuario/verAgentes",
                    },
                    {
                      title: "Productos (Próximamente)",
                      key: "NAV PRODUCTOS",
                      route: "/proximamente",
                    },
                    {
                      title: "Certificados bradescard (Próximamente)",
                      key: "NAV_CERTIFICADOS_BRADESCARD",
                      route: "/proximamente",
                    },
                    {
                      title: "Póliza aseguradora (Próximamente)",
                      key: "NAV_POLIZA_ASEGURADORA",
                      route: "/proximamente",
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes Call Center",
                  items: [
                    {
                      title: "Reporte Gerencial",
                      key: "NAV_REPORTE_GERENCIAL",
                      route: "/reporte-gerencial",
                    },
                    {
                      title: "Reporte de marcación",
                      key: "NAV_REPORTE_MARCACION",
                      route: "/reporte-marcacion",
                    },
                  ],
                },
                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes Seguros",
                  items: [
                    {
                      title: "Reporte de emisiones Hogar",
                      key: "NAV_REPORTE_EMISIONES_HOGAR",
                      route: "/reporte-emision-hogar",
                    },
                    {
                      title: "Reporte de emisiones Hogar APP",
                      key: "NAV_REPORTE_EMISIONES_HOGAR_APP",
                      route: "/reporte-emision-hogar-app",
                    },
                    {
                      title: "Reporte de emisiones Incapacidad AP",
                      key: "NAV_REPORTE_EMISIONES_INCAPACIDAD_AP",
                      route: "/reporte-emision-incapacidad-ap",
                    },
                    {
                      title: "Reporte de emisiones Vida",
                      key: "NAV_REPORTE_EMISIONES_VIDA",
                      route: "/reporte-emision-vida",
                    },
                    {
                      title: "Reporte de emision Vida + Ahorro",
                      key: "NAV_REPORTE_EMISIONES_VIDA_AHORRO",
                      route: "/reporte-emision-vida-ahorro",
                    },
                    {
                      title: "Reporte de emisiones Seguro Celular",
                      key: "NAV_REPORTE_EMISIONES_SEGURO_CELULAR",
                      route: "/reporte-emision-celular",
                    },
                    {
                      title: "Reporte de pólizas emitidas",
                      key: "NAV_REPORTE_POLIZAS_EMITIDAS",
                      route: "/polizas-emitidas",
                    },
                    {
                      title: "Reporte de cobranza Hogar (AIG)",
                      key: "NAV_REPORTE_COBRANZA_HOGAR_AIG",
                      route: "/reporte/cobranza/aig",
                    },
                    {
                      title: "Reporte de cobranza Incapacidad AP",
                      key: "NAV_REPORTE_COBRANZA_INCAPACIDAD_AP",
                      route: "/reporte/cobranza/incapacidad-ap",
                    },
                    {
                      title: "Reporte Cobranza Vida",
                      key: "NAV_REPORTE_COBRANZA_VIDA",
                      route: "/reporte/cobranza/vida-sura",
                    },
                    {
                      title: "Reporte Cobranza Vida + Ahorro",
                      key: "NAV_REPORTE_COBRANZA_VIDA_AHORRO",
                      route: "/reporte/cobranza/vida-ahorro-metlife",
                    },
                    {
                      title: "Reporte de cobranza Seguro Celular",
                      key: "NAV_REPORTE_COBRANZA_SEGURO_CELULAR",
                      route: "/reporte/cobranza/celular",
                    },
                  ],
                },

                {
                  action: "mdi-clipboard-text-multiple",
                  title: "Reportes Asistencias",
                  items: [
                    {
                      title: "Reporte de Emisión Asistencias Vigor",
                      key: "NAV_REPORTE_EMISION_ASISTENCIAS_VIGOR",
                      route: "/reporte-emision-asistencias-vigor",
                    },
                    {
                      title: "Reporte de Emisión Asistencias",
                      key: "NAV_REPORTE_EMISION_ASISTENCIAS",
                      route: "/reporte-emision-asistencias",
                    },
                    {
                      title: "Reporte de Cobranza Asistencias",
                      key: "NAV_REPORTE_COBRANZA_ASISTENCIAS",
                      route: "/reporte/cobranza/asistencias",
                    },
                    {
                      title: "Reporte de Solicitudes",
                      key: "NAV_REPORTE_SOLICITUDES",
                      route: "/reporte-solicitudes",
                    },
                    {
                      title: "Reporte de Redenciones",
                      key: "NAV_REPORTE_REDENCIONES",
                      route: "/reporte-redenciones",
                    },
                  ],
                },
                /*{
                  action: "mdi-clipboard-text-multiple",
                  title: "Ocultadas para todos los roles",
                  items: [
                    {
                      title: "Perfil",
                      route: "/usuario/ver-micuenta",
                    },
                    {
                      title: "Reporte de emiiones Hospitalización",
                      route: "/reporte-emision-hospitalizacion",
                    },
                    {
                      title: "Reporte Cobranza Hospitalización",
                      route: "/reporte/cobranza/hospitalizacion",
                    },
                    {
                      title: "Pólizas por renovar",
                      route: "/polizas-por-renovar",
                    },
                    {
                      title: "Pólizas Renovadas",
                      route: "/polizas-renovadas",
                    },
                    {
                      title: "Cuotas de Pólizas Activas",
                      route: "/cobranza-polizas-activas",
                    },
                    {
                      title: "Cartera de Clientes",
                      route: "/proximamente",
                    },
                  ],
                },*/
              ],
            },
          ]
      // Filtra quitando todas las opciones donde su key no coincida con las que hay en permisos
      // por lo tanto signfica que no tienen permiso para tales opciones
      nav[0].items = nav[0].items.filter(category => {
        category.items = category.items.filter(e => permisos_key.includes(e.key));
        return category.items.length > 0;
      });
      // console.log( "nav", nav[0])
      this.listaDesplegable = nav;
    },


    async checkPermisos(){
      const response = await getPermisos();
      localStorage.setItem("permisos", JSON.stringify(response));
      return JSON.stringify(response);
    }
  },
};
</script>
<style>
.v-carousel__controls {
  bottom: 12px;
}

.rolNota {
  font-size: 12px;
  color: rgb(192, 192, 192);
}

.v-list-item__title {
  white-space: normal !important;
}
</style>
