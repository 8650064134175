<template>
  <div>
    <FormUser :form.sync="usuario" :tipo="tipo" class="mb-12">
      <template #title>
        <h2>{{ $route.params.uuid ? "Usuarios" : "Mi cuenta" }}</h2>
      </template>

      <template #action>
        <v-row>
          <v-col cols="12" style="text-align: end;">
            <v-btn color="primary" @click="setData()" class="mr-4">
              Actualizar
            </v-btn>

            <v-btn color="primary" @click="dialog2 = true">
              Cambiar contraseña
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </FormUser>

    <!-- Change password Modal -->
    <v-dialog v-model="dialog2" max-width="500px">
      <v-card>
        <v-card-title>
          Cambio de contraseña
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-if="rol == 'AGENTE'"
            v-model="oldPassword"
            label="Contraseña Actual"
            required
            type="password"
          >
          </v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="newPassword"
            label="Nueva Contraseña"
            required
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="passwordRules"
            label="Verificar nueva contraseña"
            :rules="[passwordVerificate]"
            required
          ></v-text-field>
        </v-card-text>

        <v-card-actions style="display: flex;justify-content: flex-end;">
          <v-btn
            text
            @click="changePassword()"
            :disabled="newPassword.length < 3"
          >
            Cambiar
          </v-btn>
          <v-btn color="primary" text @click="dialog2 = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Updated data successfully Modal -->
    <v-dialog v-model="actualizadoModal" max-width="500px">
      <v-card>
        <v-card-title>
          {{ mensajeData }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="
              (actualizadoModal = false),
                $router.push(`/usuario/ver-micuenta/${$route.params.uuid}`)
            "
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Updated password successfully Modal -->
    <v-dialog v-model="actualizadoPasswordModal" max-width="500px">
      <v-card>
        <v-card-title>
          {{ mensajeCotrasena }}
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="actualizadoPasswordModal = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import FormUser from "./components/FormUsuarios.vue";

export default {
  name: "Usuario.Update",
  components: {
    FormUser,
  },
  data() {
    return {
      tipo: "update",
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: null,
      rolElegido: null,
      guardando: false,
      usuario: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        curp: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        status: null,
        motivoBaja: null,
        fechaBaja: null,
        ramo: null,
        canal: null,
        landing: null,
        vip: false,
      },
      usuarioAgente: {
        fuenteIngreso: null,
        regimen: null,
        asociadoPromotoria: null,
        tipoAgente: null,
        razonSocial: null,
        clabeBancaria: null,
        claveAgente: null,
        tipoCedula: null,
        nivelAgente: null,
        numeroCartera: null,
        cedula: null,
        rc: null,
        vigenciaCedula: null,
        vigenciaPolizaRC: null,
      },
      checkbox: false,
      uuidCambio: this.$route.params.uuid,

      password: "",
      oldPassword: "",
      newPassword: "",
      passwordRules: "",
      mensajeCotrasena: "",
      mensajeData: "",
      dialog2: false,
      actualizadoModal: false,
      actualizadoPasswordModal: false,

      canalList: [],
      ramoList: [],
      campaniaList: [],
    };
  },

  created() {},

  mounted() {
    this.getUser();
  },

  computed: {
    passwordVerificate() {
      return this.newPassword == this.passwordRules ? true : false;
    },
  },
  methods: {
    getUser() {
      let uuid = this.$route.params.uuid;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      if (this.$route.params.uuid) {
        this.tipo = "update";
        this.$axios.get(`/v1/agente/uuid/${uuid}`, config).then((response) => {
          let rolesBase =
            response.data.roles.length > 0 ? response.data.roles : [];
          let roles = [];
          rolesBase.forEach((element) => {
            if (element.rol != null) {
              roles.push(element.rol);
            }
          });
          if (roles.length > 0) {
            this.usuario.rol = roles[0];
          } else {
            this.usuario.rol = "";
          }
          this.usuario.correo = response.data.username;
          this.usuario.nombre = response.data.nombre;
          this.usuario.apellidoPaterno = response.data.apellidoPaterno;
          this.usuario.apellidoMaterno = response.data.apellidoMaterno;
          this.usuario.celular = response.data.celular;
          this.usuario.telefono = response.data.telefono;
          this.usuario.fechaNacimiento = moment(
            response.data.fechaNacimiento,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
          this.usuario.fechaRegistro = moment
            .utc(response.data.fechaRegistro)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm");
          this.usuario.sexo = response.data.sexo;
          this.usuario.nacimientoEstadoId = response.data.nacimientoEstadoId;
          this.usuario.rfc = response.data.rfc;
          // this.usuario.curp = response.data.curp
          this.usuario.password = response.data.password;
          this.usuario.status = response.data.status;
          this.usuario.ramo = response.data.ramos;
          this.usuario.canal = response.data.canales;
          this.usuario.landing = response.data.landings;

          // let from = moment(response.data.fechaBaja, "DD/MM/YYYY");
          // this.usuario.fechaBaja = from.add(1, 'days');

          this.usuario.motivoBaja = response.data.motivoBaja;
          this.usuario.fechaBaja = moment(response.data.fechaBaja)
            .add(1, "days")
            .format("DD/MM/YYYY");

          this.usuario.creadoPor = response.data.creadoPor
            ? response.data.creadoPor
            : null;
          this.usuario.fechaCreacion = response.data.fechaRegistro
            ? moment
                .utc(response.data.fechaRegistro)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : null;

          // this.creadoPor = response.data.creadoPor ? response.data.creadoPor : null
          // this.fechaCreacion = response.data.fechaRegistro ? moment.utc(response.data.fechaRegistro).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null
          // this.modificadoPor = response.data.modificadoPor ? response.data.modificadoPor : null
          // this.fechaModificacion = response.data.ultimaModificacion ? moment.utc(response.data.ultimaModificacion).subtract(6, 'hours').format('DD/MM/YYYY HH:mm:ss') : null

          // this.usuarioAgente.fuenteIngreso = response.data.fuenteIngreso ? response.data.fuenteIngreso : null
          // this.usuarioAgente.regimen = response.data.regimen ? response.data.regimen : null
          // this.usuarioAgente.asociadoPromotoria = response.data.agenteAsociadoPromotoria ? response.data.agenteAsociadoPromotoria : null
          // this.usuarioAgente.tipoAgente = response.data.tipoAgente ? response.data.tipoAgente : null
          // this.usuarioAgente.razonSocial = response.data.razonSocial ? response.data.razonSocial : null
          // this.usuarioAgente.clabeBancaria = response.data.clabeBancaria ? response.data.clabeBancaria : null
          // this.usuarioAgente.claveAgente = response.data.claveAgente ? response.data.claveAgente : null
          // this.usuarioAgente.tipoCedula = response.data.tipoCedula ? response.data.tipoCedula : null
          // this.usuarioAgente.nivelAgente = response.data.nivelAgente ? response.data.nivelAgente : null
          // this.usuarioAgente.numeroCartera = response.data.numeroCartera ? response.data.numeroCartera : null
          // this.usuarioAgente.cedula = response.data.cedula ? response.data.cedula : null
          // this.usuarioAgente.rc = response.data.rc ? response.data.rc : null
          // this.usuarioAgente.vigenciaCedula = response.data.vigenciaCedula ? moment(response.data.vigenciaCedula, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          // this.usuarioAgente.vigenciaPolizaRC = response.data.vigenciaPolizaRc ? moment(response.data.vigenciaPolizaRc, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        });
      } else {
        this.tipo = "updateCuenta";
        console.log("Entre en la funcion de carga de perfil");
        this.$axios
          .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
          .then((response) => {
            let rolBase =
              response.data.roles.length == 0
                ? "AGENT"
                : response.data.roles[0].rol;
            this.usuario.nombre = response.data.nombre;
            this.usuario.apellidoPaterno = response.data.apellidoPaterno;
            this.usuario.apellidoMaterno = response.data.apellidoMaterno;
            this.usuario.correo = response.data.username;
            this.usuario.celular = response.data.celular;
            this.usuario.telefono = response.data.telefono;
            this.usuario.fechaNacimiento = moment(
              response.data.fechaNacimiento,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY");
            this.usuario.fechaRegistro = moment
              .utc(response.data.fechaRegistro)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm");
            this.usuario.sexo = response.data.sexo;
            this.usuario.nacimientoEstadoId = response.data.nacimientoEstadoId;
            this.usuario.rfc = response.data.rfc;
            // this.usuario.curp = response.data.curp
            this.usuario.password = response.data.password;
            this.usuario.status = response.data.status;
            this.usuario.rol = rolBase;
            this.usuario.ramo = response.data.ramos;
            this.usuario.canal = response.data.canales;
            this.usuario.landing = response.data.landings;

            // this.usuarioAgente.fuenteIngreso = response.data.fuenteIngreso ? response.data.fuenteIngreso : null
            // this.usuarioAgente.regimen = response.data.regimen ? response.data.regimen : null
            // this.usuarioAgente.asociadoPromotoria = response.data.agenteAsociadoPromotoria ? response.data.agenteAsociadoPromotoria : null
            // this.usuarioAgente.tipoAgente = response.data.tipoAgente ? response.data.tipoAgente : null
            // this.usuarioAgente.razonSocial = response.data.razonSocial ? response.data.razonSocial : null
            // this.usuarioAgente.clabeBancaria = response.data.clabeBancaria ? response.data.clabeBancaria : null
            // this.usuarioAgente.claveAgente = response.data.claveAgente ? response.data.claveAgente : null
            // this.usuarioAgente.tipoCedula = response.data.tipoCedula ? response.data.tipoCedula : null
            // this.usuarioAgente.nivelAgente = response.data.nivelAgente ? response.data.nivelAgente : null
            // this.usuarioAgente.numeroCartera = response.data.numeroCartera ? response.data.numeroCartera : null
            // this.usuarioAgente.cedula = response.data.cedula ? response.data.cedula : null
            // this.usuarioAgente.rc = response.data.rc ? response.data.rc : null
            // this.usuarioAgente.vigenciaCedula = response.data.vigenciaCedula ? moment(response.data.vigenciaCedula, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
            // this.usuarioAgente.vigenciaPolizaRC = response.data.vigenciaPolizaRc ? moment(response.data.vigenciaPolizaRc, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
          });
      }
    },
    setData() {
      console.log(this.usuario.fechaBaja);
      console.log(
        moment(this.usuario.fechaBaja, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      console.log(moment(this.usuario.fechaBaja).format("YYYY-MM-DD"));
      this.guardando = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let dataAgent = {
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        sexo: this.usuario.sexo,
        fechaNacimiento: moment(
          this.usuario.fechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        telefono: this.usuario.telefono,
        celular: this.usuario.celular,
        correo: this.usuario.correo,
        rfc: this.usuario.rfc,
        // curp: this.usuario.curp,
        rol: this.usuario.rol,
        status: this.usuario.status,
        motivoBaja: this.usuario.status == -1 ? this.usuario.motivoBaja : null,
        fechaBaja:
          this.usuario.status == -1
            ? moment(this.usuario.fechaBaja, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
        ramos: this.usuario.ramo,
        canales: this.usuario.canal,
        landings: this.usuario.landing ? this.usuario.landing : null,
        // fechaRegistro: this.usuario.fechaRegistro,
        // creadoPor: this.creadoPor ? this.creadoPor : null,

        modificadoPor: this.usuario.modificadoPor,
        ultimaModificacion: this.fechaModificacion,

        // fuenteIngreso: this.usuarioAgente.fuenteIngreso ? this.usuarioAgente.fuenteIngreso : null,
        // regimen: this.usuarioAgente.regimen ? this.usuarioAgente.regimen : null,
        // agenteAsociadoPromotoria: this.usuarioAgente.asociadoPromotoria ? this.usuarioAgente.asociadoPromotoria : null,
        // tipoAgente: this.usuarioAgente.tipoAgente ? this.usuarioAgente.tipoAgente : null,
        // razonSocial: this.usuarioAgente.razonSocial ? this.usuarioAgente.razonSocial : null,
        // clabeBancaria: this.usuarioAgente.clabeBancaria ? this.usuarioAgente.clabeBancaria : null,
        // claveAgente: this.usuarioAgente.claveAgente ? this.usuarioAgente.claveAgente : null,
        // tipoCedula: this.usuarioAgente.tipoCedula ? this.usuarioAgente.tipoCedula : null,
        // nivelAgente: this.usuarioAgente.nivelAgente ? this.usuarioAgente.nivelAgente : null,
        // numeroCartera: this.usuarioAgente.numeroCartera ? this.usuarioAgente.numeroCartera : null,
        // cedula: this.usuarioAgente.cedula ? this.usuarioAgente.cedula : null,
        // rc: this.usuarioAgente.rc ? this.usuarioAgente.rc : null,
        // vigenciaCedula: this.usuarioAgente.vigenciaCedula ? moment(this.usuarioAgente.vigenciaCedula, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        // vigenciaPolizaRc: this.usuarioAgente.vigenciaPolizaRC ? moment(this.usuarioAgente.vigenciaPolizaRC, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      };
      if (this.$route.params.uuid) {
        this.$axios
          .put(
            `/v1/agente/update/${this.$route.params.uuid}`,
            dataAgent,
            config
          )
          .then((value) => {
            this.guardando = false;
            this.mensajeData =
              value.data.status == "FAILED"
                ? "Error al actualizar los datos"
                : "Actualización de datos correcta";
            value.status == 200 ? (this.actualizadoModal = true) : "";
          });
      } else {
        this.$axios
          .put(
            `/v1/agente/update/${localStorage.agenteUuid}`,
            dataAgent,
            config
          )
          .then((value) => {
            this.guardando = false;
            value.status == 200 ? (this.actualizadoModal = true) : "";
          });
      }
    },

    changePassword() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      console.log("uuid a cambiar", this.uuidCambio);
      if (
        this.rol === "ADMIN" ||
        this.rol === "ADMINAGENT" ||
        this.rol === "ADMINCC" ||
        this.rol === "MANAGER" ||
        this.rol === "DIRECTOR" ||
        this.rol === "GERENTE" ||
        this.rol === "COORDINADOR" || 
        this.rol === "SUPERVISOR"
      ) {
        let password = {
          uuid: this.uuidCambio,
          newPassword: this.newPassword.trim(),
        };
        this.$axios
          .post("/v1/agente/admin/password/update", password, config)
          .then((response) => {
            this.mensajeCotrasena =
              response.data.status == "FAILED"
                ? "Error al Actualizar la contraseña"
                : "Contraseña Actualizada";
            response.status == 200
              ? (this.actualizadoPasswordModal = true)
              : "";
          });
      } else {
        let password = {
          uuid: localStorage.agenteUuid,
          oldPassword: this.oldPassword.trim(),
          newPassword: this.newPassword.trim(),
        };
        console.log(password);
        this.$axios
          .post("/v1/agente/password/update", password, config)
          .then((response) => {
            console.log(response.data.status);
            this.mensajeCotrasena =
              response.data.status == "FAILED"
                ? "Error al Actualizar la contraseña"
                : "Contraseña Actualiza";
            response.status == 200
              ? (this.actualizadoPasswordModal = true)
              : "";
          });
      }
    },
  },
};
</script>
<style scoped></style>
