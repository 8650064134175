<template>
  <v-card flat>
    <v-row>
      <v-col cols="12">
        <slot name="title">
          <h1 style="padding-left: 15px">Usuarios</h1>
        </slot>
      </v-col>
    </v-row>

    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="4">
              <v-text-field
                name="name"
                label="Nombre(s)"
                :rules="[$rules.required]"
                v-model="form.nombre"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                name="apellidoPaterno"
                label="Apellido Paterno"
                :rules="[$rules.required]"
                v-model="form.apellidoPaterno"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                name="apellidoMaterno"
                label="Apellido Materno"
                v-model="form.apellidoMaterno"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-select
                v-model="form.sexo"
                :items="generos"
                item-text="sexo"
                item-value="id"
                label="Género"
                required
                :rules="[$rules.required]"
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="form.fechaNacimiento"
                label="Fecha de Nacimiento"
                placeholder="dd/mm/aaaa"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                item-text="estadoRepublica"
                item-value="id"
                v-model="form.nacimientoEstadoId"
                :items="estados"
                :rules="[$rules.required]"
                placeholder="Seleccionar Estado de nacimiento"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                name="telefono"
                label="Teléfono de contacto"
                placeholder="##########"
                v-mask="'##########'"
                :rules="[$rules.required]"
                v-model="form.telefono"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                name="correo"
                label="Correo electrónico"
                :rules="[$rules.email]"
                v-model="form.correo"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="form.rfc"
                label="RFC"
                required
                placeholder="XAXX010101000"
                :rules="[$rules.rfc]"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="form.rol"
                :items="rolesFiltrados"
                label="Rol"
                item-text="nombre"
                item-value="id"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>

            <v-col cols="4" v-if="tipo == 'create'">
              <v-text-field
                label="Password"
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="tipo != 'updateCuenta'">
            <v-col cols="4">
              <v-text-field
                label="Fecha ingreso"
                v-model="form.fechaRegistro"
                v-mask="'##/##/####'"
                placeholder="dd/mm/aaaa"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="form.status"
                :items="status"
                label="Estatus"
                item-text="name"
                item-value="id"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="tipo != 'updateCuenta'">
            <v-col cols="4" v-if="form.status == -1">
              <v-select
                v-model="form.motivoBaja"
                :items="motivoBajaItems"
                label="Motivo de Baja"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>

            <v-col cols="4" v-if="form.status == -1">
              <v-text-field
                label="Fecha de baja"
                v-mask="'##/##/####'"
                v-model="form.fechaBaja"
                placeholder="dd/mm/aaaa"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="tipo != 'updateCuenta'">
            <v-col cols="4">
              <v-select
                v-model="form.ramo"
                :items="ramoList"
                item-text="ramo"
                item-value="id"
                label="Ramo"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.ramo }}</span>
                  </v-chip>

                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ form.ramo.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="form.canal"
                :items="canalList"
                label="Canal"
                item-text="canal"
                item-value="id"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.canal }}</span>
                  </v-chip>

                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ form.canal.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="4"
              v-if="
                form.rol == 'ADMINCC' ||
                  form.rol == 'MANAGERCC' ||
                  form.rol == 'AGENTCC' ||
                  form.rol == 'OPERADOR'
              "
            >
              <v-select
                v-model="form.landing"
                :items="campanias"
                label="Campañas"
                item-value="id"
                item-text="name"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>

                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ form.landing.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="tipo != 'updateCuenta'">
            <v-col cols="6">
              <v-text-field
                :value="form.creadoPor"
                label="Creado por"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="form.fechaCreacion"
                label="Fecha de creación"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="tipo != 'updateCuenta'">
            <v-col cols="6">
              <v-text-field
                :value="form.modificadoPor"
                label="Modificado por"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="form.fechaModificacion"
                label="Fecha de modificacion"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>

          <slot name="action"></slot>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ListExpanded } from "~/plugins/mixins.js";
import moment from "moment";

export default {
  mixins: [ListExpanded],
  props: {
    tipo: {
      type: String,
      default: "create",
    },
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: [],
      showPassword: false,
      showPassword2: false,
      form: {
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        fechaNacimiento: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        correo: null,
        status: 0,
        motivoBaja: null,
        fechaBaja: null,
        ramo: null,
        canal: null,
        landing: null,
        vip: false,
        creadoPor: null,
        fechaCreacion: null,
        modificadoPor: null,
        fechaModificacion: null,
      },

      ramoList: [],
      canalList: [],
      campaniaList: [],
    };
  },

  computed: {
    rolesFiltrados() {

      //ADMIN PUEDE HACER LO QUE SEA TRAE PODER DIVINO EL AMIGO 
      if (this.rol == "ADMIN") return this.roles;

          //permisos segun la tabla DE Excel 
           const rolePermissions = {
          ADMINCUENTA: ['OPERADORSERVICIOS',
            'AGENTCC',
            'SUPERVISOR',
            'COORDINADOR',
            'GERENTE'],
          COORDINADOR: ['OPERADORSERVICIOS',
            'AGENTCC',
            'SUPERVISOR'],
          GERENTE: ['OPERADORSERVICIOS',
            'AGENTCC',
            'SUPERVISOR',
            'COORDINADOR'], 
          SUPERVISOR: ['OPERADORSERVICIOS', 'AGENTCC'], 

      };

      //traemos lOS PERMISOS SEGUN EL ROL QUE SE TENGA EN SESSION 
      const allowedRoles = new Set(rolePermissions[this.rol] || []);

      //FILTRAMOS Y VUALAAA😁!!! 
      return this.roles.filter((r) => allowedRoles.has(r.id));
    },
  },

  mounted() {
    this.getRoles();

    if (this.tipo != "updateCuenta") {
      this.listas();
    }
    if (this.tipo == "create") {
      // aqui agregar los campos de creado y actualizado por
      this.form.creadoPor = localStorage.getItem("agenteName");
      this.form.modificadoPor = localStorage.getItem("agenteName");
      this.form.fechaCreacion = moment().format("YYYY/MM/DD HH:mm");
      this.form.fechaModificacion = moment().format("YYYY/MM/DD HH:mm");
    } else if (this.tipo == "update") {
      this.form.fechaModificacion = moment().format("YYYY/MM/DD HH:mm");
      this.form.modificadoPor = localStorage.getItem("agenteName");
    }
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.$emit("update:form", form);
      },
    },
  },
  methods: {
    getRoles() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      this.$axios.get("/v1/agente/roles/list", config).then(({ data }) => {
        this.roles = data.map((e) => ({
          id: e.rol,
          nombre: e.nombre,
        }));
      });
    },

    async listas() {
      try {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        this.$axios.get(`/v1/canal/list`, config).then((response) => {
          this.canalList = response.data;
        });
        this.$axios.get(`/v1/ramo/list`, config).then((response) => {
          this.ramoList = response.data;
        });
        this.$axios.get(`/v1/campania/list`, config).then((response) => {
          this.campaniaList = response.data;
        });
      } catch (exception) {
        console.error(exception);
      }
    },
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
</style>
