<template>
  <div class="mb-12">
    <v-row class="mb-10" dense align="center">
      <v-img
        :max-height="height_log1"
        :max-width="width_log1"
        :src="logo_1"
      ></v-img>
      <h2 class="ml-5">Leads bradescard</h2>
      <v-spacer />
      <v-img
        :max-height="width_log2"
        :max-width="height_log2"
        :src="logo_2"
      ></v-img>
    </v-row>

    <v-row class="text-center" justify="center">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <v-card dense>
          <v-card-text v-if="renderGhapics">
            <div id="pie" class="chart"></div>
          </v-card-text>
          <v-card-text v-else>
            <v-progress-circular :size="100" indeterminate color="primary" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <v-card dense>
          <v-card-text v-if="renderGhapics">
            <div id="bar" class="chart"></div>
          </v-card-text>
          <v-card-text v-else>
            <v-progress-circular :size="100" indeterminate color="primary" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="showListadoMarcacion">
        <v-card dense>
          <v-toolbar flat>
            <v-toolbar-title>Leads para marcación</v-toolbar-title>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="Disposiciones"
            :items-per-page="5"
            class="elevation-1"
            dense
          >
            <template #no-data>
              No se encontro registros asignados
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                color="success"
                :to="{
                  name: 'DetalleLeadBradescard',
                  params: { uuid: item.uuid },
                }"
              >
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card dense>
          <v-toolbar flat>
            <v-toolbar-title>Agendas del día</v-toolbar-title>
          </v-toolbar>

          <v-data-table
            :headers="headAgenda"
            :items="agenda"
            :items-per-page="5"
            class="elevation-1"
            dense
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                :to="{
                  name: 'DetalleLeadBradescard',
                  params: { uuid: item.uuid },
                }"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.medio="{ item }">
              {{ item.medio ? item.medio : "No disponible" }}
            </template>
            <template #no-data>
              No se encontro registros asignados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios";
import LogoSegurify from "@/assets/intermx-1.svg";
import LogoBradescard from "@/assets/logo-bradescard-horizontal.png";
import marcacionAutoMixin from "./marcacionAuto.mixin";

export default {
  name: "Bradescard.ViewBradescard",

  mixins: [marcacionAutoMixin],

  data: function() {
    return {
      logo_1: LogoSegurify,
      width_log1: 80,
      height_log1: 80,

      logo_2: LogoBradescard,

      width_log2: 80,
      height_log2: 250,

      showListadoMarcacion: false,

      renderGhapics: true,
      chart: null,
      chart2: null,
      headers: [
        { text: "Campaña", align: "start", sortable: false, value: "medio" },
        { text: "Productos", value: "producto" },
        { text: "Status", value: "status" },
        { text: "Leads", value: "nombre" },
        { text: "Etiqueta", value: "etiqueta", sortable: false },
        { text: "Llamar", value: "actions", sortable: false },
      ],
      agenteUuid: localStorage.agenteUuid,
      agenteAccessToken: localStorage.getItem("agenteAccessToken"),
      pieLeadsChart: {},
      barEmisionesChart: {},
      barChart: {},
      pieLeadsChartData: [],
      agenda: [],
      headAgenda: [
        { text: "Nombre", align: "start", sortable: false, value: "nombre" },
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "producto",
        },
        { text: "Fecha de llamada agendada", value: "proximaLlamada" },
        { text: "Ver", value: "actions", sortable: false },
      ],
      Disposiciones: [],
    };
  },
  watch: {
    "$vuetify.breakpoint.xs": function(val) {
      if (val) {
        this.width_log1 = 50;
        this.height_log1 = 80;

        this.width_log2 = 90;
        this.height_log2 = 100;

        this.renderGhapics = false;
        this.$nextTick(() => {
          this.renderGhapics = true;
          setTimeout(() => {
            this.loadPiechart();
            this.loadBarChart();
          }, 300);
        });
      }
    },
    "$vuetify.breakpoint.sm": function(val) {
      // console.log('sm', val)

      if (val) {
        this.width_log1 = 60;
        this.height_log1 = 80;

        this.width_log2 = 160;
        this.height_log2 = 200;

        this.renderGhapics = false;
        this.$nextTick(() => {
          this.renderGhapics = true;
          setTimeout(() => {
            this.loadPiechart();
            this.loadBarChart();
          }, 300);
        });
      }
    },
    "$vuetify.breakpoint.md": function(val) {
      // console.log('md', val)
      if (val) {
        this.width_log1 = 80;
        this.height_log1 = 80;

        this.width_log2 = 90;
        this.height_log2 = 300;

        this.renderGhapics = false;
        this.$nextTick(() => {
          this.renderGhapics = true;
          setTimeout(() => {
            this.loadPiechart();
            this.loadBarChart();
          }, 300);
        });
      }
    },
    "$vuetify.breakpoint.lg": function(val) {
      // console.log('lg', val)

      if (val) {
        this.width_log1 = 80;
        this.height_log1 = 80;

        this.width_log2 = 90;
        this.height_log2 = 300;

        this.renderGhapics = false;
        this.$nextTick(() => {
          this.renderGhapics = true;
          setTimeout(() => {
            this.loadPiechart();
            this.loadBarChart();
          }, 300);
        });
      }
    },
    "$vuetify.breakpoint.xl": function(val) {
      // console.log('xl', val)
      if (val) {
        this.width_log1 = 80;
        this.height_log1 = 80;

        this.width_log2 = 90;
        this.height_log2 = 300;
        this.renderGhapics = false;

        this.$nextTick(() => {
          this.renderGhapics = true;
          setTimeout(() => {
            this.loadPiechart();
            this.loadBarChart();
          }, 300);
        });
      }
    },
  },
  mounted() {
    this.loadAgenda();
    this.loadPiechart();
    this.loadBarChart();
    //this.loadDisposiciones();
    this.loadAgenda();
    this.pieLeadsChart.on("click", (params) => {
      if (params.name == "Nuevo") {
        this.$router.push(`/bradescard/leads-bradescard`);
      } else {
        this.$router.push(
          `/bradescard/leads-bradescard-dashboard/${params.name}`
        );
      }
    });

    this.barChart.on("click", (params) => {
      this.$router.push(
        `/bradescard/polizas-bradescard-dashboard/${params.name}`
      );
    });
  },
  methods: {
    loadPiechart() {
      var options = {
        title: {
          text: "Estatus Leads",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
          type: "scroll",
        },
        series: [
          {
            name: "Estatus Leads",
            type: "pie",
            radius: "50%",
            avoidLabelOverlap: false, // evita la superpósicon de etiquetas
            data: [
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.pieLeadsChart = this.$chart.init(document.getElementById("pie"));
      this.pieLeadsChart.setOption(options);

      var data = [];
      mainAxios
        .get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
          headers: {
            Authorization: `Bearer ${this.agenteAccessToken}`,
          },
        })
        .then((response) => {
          Object.keys(response.data.Leads).forEach((key) => {
            if (key != "Nuevo") {
              data.push({
                value: response.data.Leads[key],
                name: key,
              });
            }
          });
          options.series[0].data = data;
          this.pieLeadsChart.setOption(options);
        });
    },
    loadBarChart() {
      var options = {
        title: {
          text: "Estatus Polizas",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
          type: "scroll",
        },
        series: [
          {
            name: "Estatus Polizas",
            type: "pie",
            radius: "100%",
            avoidLabelOverlap: false, // evita la superpósicon de etiquetas
            data: [
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.barChart = this.$chart.init(document.getElementById("bar"));
      this.barChart.setOption(options);
      var data = [];
      mainAxios
        .get(`v1/bradescard/dashboard/data/${this.agenteUuid}`, {
          headers: {
            Authorization: `Bearer ${this.agenteAccessToken}`,
          },
        })
        .then((response) => {
          Object.keys(response.data.Emisiones).forEach((key) => {
            data.push({
              name: key,
              value: response.data.Emisiones[key],
            });
          });
          options.series[0].data = data;
          this.barChart.setOption(options);
        });
    },
    async loadDisposiciones() {
      try {
        let { data } = await this.$axios.get(
          `/v1/bradescard/lead/marcacion/${localStorage.agenteUuid}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        this.Disposiciones = data;
      } catch (exception) {
        console.error(exception);
      }
    },
    async loadAgenda() {
      try {
        let { data } = await this.$axios.get(
          `v1/bradescard/agenda/${localStorage.agenteUuid}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          }
        );
        // console.log(data);
        this.agenda = data;
        // this.agenda = [];
      } catch (exception) {
        console.error(exception);
      }
    },
  },
};
</script>

<style>
.chart {
  width: 97%;
}
</style>
