var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permisos && _vm.permisos.includes('FUN_RETOMAR_POLIZA_VENTA_NO_EMITIDA'))?_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"text-subtitle-1"},[_vm._v("COTIZACIONES/POLIZAS")]),_c('v-spacer'),((_vm.view == 'agente' || _vm.permisos.includes('FUN_RETOMAR_POLIZA_VENTA_NO_EMITIDA')) && !_vm.restringirVenta && _vm.checkNueva)?_c('v-btn',{attrs:{"dark":"","color":"primary","small":""},on:{"click":_vm.open}},[_vm._v(" Crear ")]):_vm._e()],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.polizas,"item-key":"name","loading-text":"Cargando...","dense":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStatus(item.status))+" ")]}},{key:"item.poliza",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.poliza ? item.poliza : "No disponible")+" ")]}},{key:"item.productoId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.obtenerNombreProducto(item.productoId))+" ")]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push('/emision/' + item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)}),(_vm.dialog)?_c('FormPolizaDialog',{attrs:{"poliza":_vm.current_poliza,"identificador":_vm.identificador,"producto":_vm.producto,"productoAseguradora":_vm.productoAseguradora,"active":_vm.dialog,"corte":this.corte},on:{"updateEmisiones":_vm.getPolizas,"update:active":function($event){_vm.dialog=$event}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }