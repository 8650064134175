<template>
    <div>
      <v-data-table
        :items="emisiones"
        :headers="columnsPolizas"
        :loading="loading"
        no-data-text="Sin polizas para mostrar"
        no-results-text="Sin resultados para mostrar"
        loading-text="Cargando..."
        :footer-props="{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Elementos por página',
        }"
      >

        <template #item.ver="{ item }">
          <v-btn icon x-small @click="verSolicitud(item.id)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
  
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          <div v-if="itemsLength">
            Mostrando {{ pageStart }} - {{ pageStop }} de
            {{ itemsLength }} polizas
          </div>
        </template>
      </v-data-table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      emisiones: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        columnsPolizas: [
          { text: "ID", value: "id" },
          { text: "Certificado", value: "poliza" },
          { text: "Estatus", value: "status" },
          { text: "Fecha Emisión", value: "fechaEmision" },
          { text: "Fin de Vigencia", value: "finVigencia" },
          { text: "Producto", value: "producto_aseguradora" },
          { text: "Socio", value: "producto_lead" },
          { text: "Cliente", value: "cliente" },
          { text: "RFC", value: "rfc" },
          { text: "Ver poliza", value: "ver" },
        ],
      };
    },
    methods: {
      verSolicitud(id) {
        this.$router.push(`/emision/${id}`);
      },
    },
  };
  </script>