<template>
    <component v-if="view != null" :is="rolComponent[view].component" :detalles="detalles"></component>
</template>

<script>
    import DetallesLeadAdmin from "./admin/leads/DetallesLeads.vue";
    import DetallesLeadsAgent from "./agentes/leads/DetallesLeads.vue";
    import { mapMutations, mapActions } from 'vuex'

    export default {
        name: 'bradescard.leads',
        props: ['detalles'],
        data(){
            return{
                roles: JSON.parse(localStorage.getItem("roles")),
                view: null,
                rolComponent:{
                    admin:{
                        component: DetallesLeadAdmin
                    },
                    agente:{
                        component:DetallesLeadsAgent
                    }
                }
            }
        }, beforeMount(){
            this.getRole();
            this.obtenerProducto();
        },
        methods:{
            ...mapMutations("polizas", ['setPolizas']),
            ...mapMutations("llamadas", ['setLlamadas']),
            ...mapActions('polizas', ['obtenerProducto']),
            getRole(){
                var roles=[]
                this.roles.forEach((element) => {
                    roles.push(element.rol)
                });

                const rolesAdmin = ["ADMIN", "ADMINCC", 'MANAGERCC', 'DIRECTOR', 'FINANZAS', "OPERACIONES", "OPERADOR", "COORDINADOR", "SUPERVISOR", "GERENTE", "OPERADORSERVICIOS"];
                const rolesAgents = ["AGENTCC"];
                roles.forEach((element)=>{
                    if(rolesAdmin.includes(element)){
                        this.view = 'admin';
                    }
                    if(rolesAgents.includes(element)){
                        this.view = 'agente';
                    }
                });
            }, 
        }, destroyed(){
            this.setPolizas([]);
            this.setLlamadas([]);
        }
    }
</script>

<style>

</style>
