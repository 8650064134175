<template>
  <div>
    <div class="py-2 px-5">
      <DashboardTitle class="mb-2 mt-1"></DashboardTitle>
      <v-row>
        <v-col cols="12" md="6">
          <BuscadorPolizasForm
            :currentFilters="filtros"
            :loading="loading"
            @searchUpdated="getPolizasForFilters"
          ></BuscadorPolizasForm>
        </v-col>

        <v-col
          v-if="permisos.includes('FUN_CREACION_EMISION_DIRECTA')"
          cols="12"
          md="6"
        >
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              large
              :block="$vuetify.breakpoint.smAndDown"
              width="200"
              @click="showPolicyCreation = true"
            >
              <v-icon>mdi-plus</v-icon>
              <span>Crear</span>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-toolbar dense flat dark color="primary">
              <v-toolbar-title>
                <span>Resultados de la búsqueda</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <download-csv
                v-if="puedeCrear"
                name="Solicitudes.csv"
                :data="downloadData"
              >
                <v-btn color="#fff" outlined>
                  <v-icon small class="mr-2">
                    mdi-file-excel
                  </v-icon>
                  <span>Descargar</span>
                </v-btn>
              </download-csv>
            </v-toolbar>
            <v-card-text>
              <PolizasTable
                :loading="loading"
                :emisiones="emisiones"
              ></PolizasTable>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <policy-form
      v-if="showPolicyCreation"
      :show="showPolicyCreation"
      @close="showPolicyCreation = false"
    ></policy-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mainAxios } from "../../mainAxios";
const requestConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.agenteAccessToken,
  },
};
var moment = require("moment-timezone");
import BuscadorPolizasForm from "./BuscadorPolizasForm.vue";
import DashboardTitle from "./DashboardTitle.vue";
import PolizasTable from "./PolizasTable.vue";

export default {
  components: {
    BuscadorPolizasForm,
    DashboardTitle,
    PolizasTable,
    "policy-form": () => import("@/components/emision/InboundPolicyForm.vue"),
  },
  computed: {
    ...mapState("permisos", ["permisos"]),
  },
  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      downloadText: "",
      reportType: "lead",
      titulo: "",
      emisiones: [],
      search: "",
      descargarEmisiones: [],
      emisionesTabla: [],
      searchNuevas: "",
      searchPendientesPago: "",
      searchPagadasParcial: "",
      searchPagadasTotal: "",
      searchPorRenovar: "",
      searchRenovadas: "",
      searchFinalizadas: "",
      searchCanceladas: "",
      bloquear: true,
      productos: [],
      polizasNuevas: 0,
      polizasEmitidasPendientesDePago: 0,
      polizasPagadasParcial: 0,
      polizasPagadasTotalVigentes: 0,
      polizasPorRenovar: 0,
      polizasRenovadas: 0,
      polizasFinalizadas: 0,
      polizasCanceladas: 0,
      columnsPolizas: [
        { text: "Póliza", value: "idMostrar" },
        { text: "Cliente", value: "cliente" },
        { text: "Agente", value: "agente" },
        { text: "Estatus Póliza", value: "status" },
        { text: "Prima Neta", value: "primaNeta" },
        { text: "Inicio de Vigencia", value: "inicioVigencia" },
        { text: "Fin de Vigencia", value: "finVigencia" },
        { text: "Periodicidad", value: "periodicidad" },
        { text: "Ramo", value: "ramo" },
        { text: "Aseguradora", value: "aseguradora" },
        { text: "Ejecutivo", value: "ejecutivo" },
        { text: "Ver Emisión", value: "id" },
      ],
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      usuarios: [],
      showPolicyCreation: false,
    };
  },
  mounted() {
    // this.getAgentes();
  },
  methods: {
    getPolizasForFilters(body) {
      this.$axios
        .post(`/v1/emision/list/filtros`, body, this.requestConfig)
        .then(({ data }) => {
          this.loading = false;
          this.emisiones = [];
          data.map((element) => {
            this.emisiones.push({
              idMostrar: element.poliza ? element.poliza : "No Disponible",
              cliente: element.titular ? element.titular : "No Disponible",
              agente: element.nombreAgente
                ? element.nombreAgente
                : "No Disponible",
              status:
                element.status == 0
                  ? "Nueva"
                  : element.status == 1
                  ? "Emitida Pendiente de Pago"
                  : element.status == 2
                  ? "Pagada Parcial"
                  : element.status == 3
                  ? "Pagada Total"
                  : element.status == 4
                  ? "Renovada"
                  : element.status == 5
                  ? "Finalizada"
                  : element.status == 8
                  ? "Emitido el certificado"
                  : "Cancelada",
              primaNeta: element.primaNeta
                ? element.primaNeta
                : "No Disponible",
              inicioVigencia: element.inicio
                ? moment(element.inicio, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY"
                  )
                : "No Disponible",
              finVigencia: element.fin
                ? moment(element.fin, "YYYY-MM-DD HH:mm:ss").format(
                    "DD/MM/YYYY"
                  )
                : "No Disponible",
              periodicidad: element.periodicidad
                ? element.periodicidad
                : "No Disponible",
              ramo: element.ramo ? element.ramo : "No Disponible",
              aseguradora: element.aseguradoraNombre
                ? element.aseguradoraNombre
                : "No Disponible",
              ejecutivo: element.ejecutivoNombre
                ? element.ejecutivoNombre
                : "No Disponible",
              id: element.id,
              poliza: element.poliza || "No disponible",
              fechaEmision: element.fechaEmision,
              producto_aseguradora: element.productoAseguradora,
              producto_lead: element.productoLead,
              rfc: element.rfc,
            });
          });
          this.filtros = body;
        });
    },
    getNombre(id) {
      var result = this.usuarios.find((usuario) => usuario.id == id);
      return result.nombreCompleto;
    },
    getAgentes() {
      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        this.usuarios = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
          rol: e.rol,
        }));
      });
    },
  },
};
</script>

<style scoped>
.card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid black;
  cursor: pointer;
}

.card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid black;
  background-color: rgba(21, 101, 192, 1);
}
.card:hover:before {
  transform: scale(6.15);
}
</style>
