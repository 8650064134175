var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","flat":"","color":"#133B7D"}},[_c('v-toolbar-title',[_c('span',[_vm._v("Resultados de búsqueda")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.solicitudes.solicitudes,"headers":_vm.headers,"server-items-length":_vm.solicitudes.total,"no-data-text":"Sin solicitudes para mostrar","loading-text":"Buscando solicitudes...","loading":_vm.loading,"footer-props":{
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Elementos por página',
        },"items-per-page":_vm.elementosPagina},on:{"update:page":function($event){_vm.pagina = $event},"update:items-per-page":function($event){_vm.elementosPagina = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"close-icon":"mdi-delete","filter":"","color":_vm.getColorChip(item.statusId)}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.status)+" ")])])]}},{key:"item.view",fn:function(ref){
        var item = ref.item;
return [(_vm.permisos && _vm.permisos.includes('FUN_REGISTRO_DE_LA_SOLICITUD'))?_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.changeRoute(item, false)}}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}])})],1)],1),_c('solicitudDialogForm',{attrs:{"show":_vm.showCreateModal,"emisionId":_vm.emisionId,"onlyRead":_vm.onlyRead,"route":_vm.route},on:{"close":function($event){_vm.showCreateModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }