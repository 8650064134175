var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticStyle:{"padding-left":"30px"},attrs:{"cols":"10"}},[_c('h1',[_vm._v(" Usuarios "+_vm._s(_vm.rol == "ADMINCC" || _vm.rol == "MANAGERCC" ? "Call Center" : "")+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.canViewCreate),expression:"canViewCreate"}],staticClass:"ml-11 mt-2",staticStyle:{"align-items":"right"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/usuario/crear')}}},[_vm._v(" Crear ")])],1)],1)],1),_c('v-col',{staticClass:"mb-10",staticStyle:{"padding":"30px"},attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.agentesTable,"items":_vm.report,"item-key":"name","search":_vm.search,"loading":_vm.loading,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.sexo",fn:function(ref){
var item = ref.item;
return [(item.sexo == 1)?_c('div',[_vm._v("Masculino")]):_vm._e(),(item.sexo == 2)?_c('div',[_vm._v("Femenino")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == -1)?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("Baja")])],1):_vm._e(),(item.status == 0)?_c('div',[_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v("Nuevo")])],1):_vm._e(),(item.status == 1)?_c('div',[_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v("Activo")])],1):_vm._e(),(item.status == 2)?_c('div',[_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v("Inactivo")])],1):_vm._e()]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item.uuid)}}},[_vm._v(" mdi-eye ")])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [(Array.isArray(item.roles) && item.roles.length > 0)?_c('div',[_vm._v(" "+_vm._s(item.roles.map(function (r) { return r.rol || "Desconocido"; }).join(", "))+" ")]):_c('div',[_vm._v("No Disponible")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }