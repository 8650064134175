<template>
  <v-app>
    <div :class="{ 'show-padding': !isLogin }">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </div>

    <policy-form
      :show="showPolicyCreation"
      @close="showPolicyCreation = false"
    ></policy-form>
  </v-app>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import { mainAxios } from "@/mainAxios";
import { mapState } from 'vuex';

export default {
  name: "App",

  components: {
    "policy-form": () => import("@/components/emision/InboundPolicyForm.vue"),
  },

  data: () => ({
    rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    config: {
      headers: {
        Authorization: "Bearer " + localStorage.agenteAccessToken,
      },
    },
    leadsLlamada: [],
    showPolicyCreation: false,
  }),

  computed: {
    ...mapState('permisos', ['permisos']),
    isLogin() {
      return this.$route.path == "/";
    },
  },

  mounted() {
    // Se desactiva SDK de CIO debido a fallos. Se sustituye por emisión directa en apartado de pólizas
    // this.validateCIO();
  },
  watch:{
    permisos(){
      this.validateCIO();
    }
  },
  methods: {
    validateCIO(){
      if (
      this.permisos && this.permisos.includes('VENTA_INBOUND')
      ) {
        loadScript(
          "https://aligev55.zw-callitonce.alestra.net.mx/agentdesktop/libs/servicepattern-sdk-v1.js"
        )
          .then(() => {
            window.bpspat.api.init(
              "https://aligev55.zw-callitonce.alestra.net.mx"
            );
          })
          .catch(() => {
            console.error(
              "Fallo al carga el sdk, Refresque la pagina para realizar la llamada"
            );
            location.reload();
          });
        setTimeout(() => {
          this.checkStatus();
          this.intervalo = window.setInterval(() => {
            this.checkStatus();
          }, 10000);
        }, 10000);
      }
    },
    checkStatus() {
      if (!localStorage.agenteAccessToken) return;
      window.bpspat.api.getState(this.statusChecked);
    },
    statusChecked(state) {
      switch (state.data.status) {
        case "busy":
          {
            const jsonActividadActual =
              localStorage.getItem("jsonLlamada") !== null
                ? JSON.parse(localStorage.getItem("jsonLlamada"))
                : { numero: null, global_id: null };
            if (
              jsonActividadActual.global_id !=
              state.data.interactions[0].global_id
            ) {
              const nuevaLlamada = {
                numero: state.data.interactions[0].phone_number,
                global_id: state.data.interactions[0].global_id,
              };
              localStorage.setItem("jsonLlamada", JSON.stringify(nuevaLlamada));
              this.checkClienteByPhone(nuevaLlamada.numero);
              //this.checkLeadByPhone(nuevaLlamada.numero)
            }
          }
          break;
      }
    },
    async checkClienteByPhone(phone) {
      const response = await mainAxios.get(
        `/v1/getClienteByTelefono/${phone}`,
        this.config
      );
      const total = response.data.data.total;
      if (total != 0) {
        const onCall = {
          clase: "Cliente",
          telefono: phone,
        };
        localStorage.setItem("onCall", JSON.stringify(onCall));
        this.$router.push("/lista-clientes");
      } else {
        console.log("this", this);
        this.checkLeadByPhone(phone);
      }
    },
    checkLeadByPhone(phone) {
      mainAxios
        .get(`/v1/bradescard/getLeadByTelelfono/${phone}`, this.config)
        .then((response) => {
          const total = response.data.data.total;
          if (total != 0) {
            const onCall = {
              clase: "Lead",
              telefono: phone,
              data: response.data.data,
            };
            localStorage.setItem("onCall", JSON.stringify(onCall));
            this.$router.push("/bradescard/leads/dashboard");
          } else {
            this.showPolicyCreation = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.show-padding {
  padding-left: 40px;
}
</style>
