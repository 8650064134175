<template>
  <div class="mb-16">
    <v-row class="mt-1" style="padding: 20px">
      <v-toolbar>
        <v-toolbar-title>
          <h2>{{ this.$route.params.uuid ? "Usuarios " : "Mi cuenta" }}</h2>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="verCuentaEditable()" v-if="canEdicion">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          @click="$router.go(-1)"
          style="background-color: #ff0042; color: white;"
        >
          FINALIZAR
        </v-btn>
      </v-toolbar>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="7" style="padding: 20px">
        <v-card>
          <v-row style="padding: 10px">
            <v-col cols="4">
              <v-text-field
                name="name"
                label="Nombre(s)"
                v-model="usuario.nombre"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                name="apellidoPaterno"
                label="Apellido Paterno"
                readonly
                v-model="usuario.apellidoPaterno"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                name="apellidoMaterno"
                label="Apellido Materno"
                readonly
                v-model="usuario.apellidoMaterno"
              >
              </v-text-field>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="usuario.sexo"
                :items="sexoList"
                item-text="sexo"
                item-value="id"
                label="Género"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="usuario.fechaNacimiento"
                label="Fecha de Nacimiento"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                item-text="estadoRepublica"
                item-value="id"
                v-model="usuario.nacimientoEstadoId"
                :items="estados"
                readonly
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-text-field
                name="telefono"
                label="Teléfono de contacto"
                v-model="usuario.telefono"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                name="correo"
                label="Correo electrónico"
                v-model="usuario.correo"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="usuario.rfc"
                label="RFC"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="4" v-if="$route.params.uuid">
              <v-select
                v-model="usuario.rol"
                :items="roles"
                label="Rol"
                item-text="nombre"
                item-value="id"
                readonly
              >
              </v-select>
            </v-col>

            <v-col cols="4" v-if="$route.params.uuid">
              <v-select
                v-model="usuario.status"
                :items="status"
                label="Estatus"
                item-text="name"
                item-value="id"
                readonly
              ></v-select>
            </v-col>

            <v-col cols="4">
              <v-text-field
                label="Fecha Ingreso"
                placeholder="dd/mm/aaaa"
                v-model="usuario.fechaRegistro"
                readonly
              >
              </v-text-field>
            </v-col>

            <v-col cols="4" v-if="usuario.status == -1 && $route.params.uuid">
              <v-select
                v-model="usuario.motivoBaja"
                :items="motivoBajaItems"
                label="Motivo de Baja"
                readonly
              >
              </v-select>
            </v-col>

            <v-col cols="4" v-if="$route.params.uuid">
              <v-select
                v-model="usuario.ramo"
                :items="ramoList"
                item-text="ramo"
                item-value="id"
                label="Ramo"
                multiple
                readonly
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.ramo }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ usuario.ramo.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="4" v-if="$route.params.uuid">
              <v-select
                v-model="usuario.canal"
                :items="canalList"
                label="Canal"
                item-text="canal"
                item-value="id"
                multiple
                readonly
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.canal }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ usuario.canal.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="4"
              v-if="
                (usuario.rol == 'ADMINCC' ||
                  usuario.rol == 'MANAGERCC' ||
                  usuario.rol == 'AGENTCC') &&
                  $route.params.uuid
              "
            >
              <v-select
                v-model="usuario.landing"
                :items="landings"
                label="Campaña"
                item-value="id"
                item-text="name"
                multiple
                readonly
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip color="primary" v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>

                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ usuario.landing.length - 1 }} más)
                  </span>
                </template>
              </v-select>
            </v-col>

            <!-- <v-col cols="4" v-if="$route.params.uuid">
                    <v-checkbox
                    label="VIP"
                    v-model="usuario.vip"
                    readonly
                    ></v-checkbox>
                </v-col> -->

            <!-- ONLY IF ROL = AGENTE -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-select
                    label="Fuente de Ingreso"
                    v-model="usuarioAgente.fuenteIngreso"
                    :items="fuenteIngresoItems"
                    readonly
                    ></v-select>
                </v-col> -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-select
                    label="Régimen"
                    v-model="usuarioAgente.regimen"
                    :items="regimenItems"
                    readonly
                    ></v-select>
                </v-col> -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-select
                    label="Agente asociado a una promotoria"
                    v-model="usuarioAgente.asociadoPromotoria"
                    :items="asociadoPromotoriaItems"
                    item-text="name"
                    item-value="id"
                    multiple
                    readonly
                    >
                    <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" v-if="index === 0">
                        <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                        >
                        (+{{ usuarioAgente.asociadoPromotoria.length - 1 }} más)
                        </span>
                    </template>
                    </v-select>
                </v-col> -->

            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-select
                    label="Tipo de Agente"
                    v-model="usuarioAgente.tipoAgente"
                    :items="tipoAgenteItems"
                    readonly
                    ></v-select>
                </v-col> -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-text-field
                    label="Razón Social"
                    v-model="usuarioAgente.razonSocial"
                    readonly
                    ></v-text-field>
                </v-col> -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-text-field
                    label="Clabe Bancaria para transferencia"
                    v-model="usuarioAgente.clabeBancaria"
                    readonly
                    ></v-text-field>
                </v-col> -->

            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="Clave del Agente"
                    v-model="usuarioAgente.claveAgente"
                    readonly
                    ></v-text-field>
                </v-col> -->
            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-select
                    label="Tipo de Cédula"
                    v-model="usuarioAgente.tipoCedula"
                    :items="tipoCedulaItems"
                    readonly
                    ></v-select>
                </v-col> -->
            <!-- <v-col cols="4" v-if="mostrarInputsAgente()">
                    <v-select
                    label="Nivel Agente"
                    v-model="usuarioAgente.nivelAgente"
                    :items="nivelAgenteItems"
                    readonly
                    ></v-select>
                </v-col> -->

            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="Número de Cartera"
                    v-model="usuarioAgente.numeroCartera"
                    type="number"
                    readonly
                    ></v-text-field>
                </v-col> -->
            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="Cedula"
                    v-model="usuarioAgente.cedula"
                    readonly
                    ></v-text-field>
                </v-col> -->
            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="RC"
                    v-model="usuarioAgente.rc"
                    readonly
                    ></v-text-field>
                </v-col> -->

            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="Vigencia de Cédula"
                    v-model="usuarioAgente.vigenciaCedula"
                    readonly
                    ></v-text-field>
                </v-col> -->
            <!-- <v-col cols="4" v-if="usuarioAgente.tipoAgente != 'Comisionista' && mostrarInputsAgente()">
                    <v-text-field
                    label="Vigencia póliza RC"
                    v-model="usuarioAgente.vigenciaPolizaRC"
                    readonly
                    ></v-text-field>
                </v-col> -->

            <v-col cols="6" v-if="$route.params.uuid">
              <v-text-field
                label="Creado por"
                v-model="creadoPor"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="$route.params.uuid">
              <v-text-field
                label="Fecha de creación"
                v-model="fechaCreacion"
              ></v-text-field>
            </v-col>

            <v-col cols="6" v-if="$route.params.uuid">
              <v-text-field
                label="Modificado por"
                v-model="modificadoPor"
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="$route.params.uuid">
              <v-text-field
                label="Fecha de modificacion"
                v-model="fechaModificacion"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="5">
        <v-card class="pt-1">
          <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1"
              >NOTAS Y DOCUMENTOS</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="documentsDialog = true">
              Crear
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>

          <v-data-table
            :headers="columnsDocumentos"
            :items="documentos"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
          >
            <template v-slot:item.url="{ item }">
              <v-icon small class="mr-2" @click="verDocumento(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="mt-4">
          <v-toolbar flat dense>
            <v-toolbar-title class="text-subtitle-1">EMAILS</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="newCorreoDialog = true">
              Crear
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>
          <v-data-table
            :headers="columnsEmails"
            :items="correos"
            item-key="name"
            class="elevation-1"
            loading-text="Cargando..."
            dense
          >
            <template v-slot:item="row">
              <tr>
                <td>{{ row.item.asunto }}</td>
                <td>{{ row.item.fecha }}</td>
                <td>{{ row.item.remitente }}</td>
                <td style="cursor:pointer;" @click="verDetalleCorreo(row.item)">
                  <v-icon light small class="mr-2">mdi-eye</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- MODAL VER DOCUMENTOS -->
    <v-dialog v-model="dialogDetalleDocumentos" max-width="750px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Detalle de Documento / Nota</span>
        </v-card-title>

        <template>
          <v-container style="padding: 30px">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Nombre"
                  v-model="detallesDocumento.nombre"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  label="¿Enviar por correo electrónico?"
                  readonly
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Tipo"
                  :items="tipoItems"
                  v-model="detallesDocumento.tipo"
                  required
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="6" v-if="detallesDocumento.tipo == 'Documento'">
                <v-select
                  label="Tipo de Doocumento*"
                  :items="tipoDocumentoItems"
                  v-model="detallesDocumento.tipoDocumento"
                  required
                  readonly
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Descripción"
                  v-model="detallesDocumento.descripcion"
                  required
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" v-if="detallesDocumento.url">
                <template>
                  <v-file-input
                    v-model="documentos"
                    color="primary"
                    label="Adjuntos*"
                  >
                    <template v-slot:selection="{ index }">
                      <v-chip
                        v-if="index < 1"
                        color="primary"
                        dark
                        label
                        small
                        @click="detallesDocumento.url"
                      >
                        {{ detallesDocumento.nombre }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ documentos.length - 2 }} Archivos(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              <v-col cols="2" v-if="detallesDocumento.url">
                <v-btn
                  text
                  :href="detallesDocumento.url"
                  small
                  color="primary"
                  class="mt-4"
                >
                  <v-icon>mdi-download</v-icon>
                  Descargar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Creado por"
                  v-model="detallesDocumento.creadoPor"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Fecha de Creación"
                  v-mask="'##/##/####'"
                  v-model="detallesDocumento.fecha"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card>
    </v-dialog>

    <!-- MODAL DE CORREO -->
    <v-dialog v-model="dialogCorreo" width="600px">
      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Para"
              v-model="correoOpen.remitente"
              hide-details="auto"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="CC"
              v-model="correoOpen.cc"
              hide-details="auto"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="BCC"
              v-model="correoOpen.bcc"
              hide-details="auto"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Asunto"
              v-model="correoOpen.asunto"
              hide-details="auto"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="correoOpen.cuerpo"
              label="Mensaje"
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="correoOpen.documentos">
          <v-col>
            <p>Descargar Adjuntos</p>
          </v-col>
        </v-row>
        <v-row v-if="correoOpen.documentos">
          <v-col>
            <v-chip
              v-for="(documento, index) in correoOpen.documentos"
              v-bind:key="index"
              color="primary"
              outlined
              :href="documento.url"
              style="cursor: pointer"
            >
              <v-icon left>
                mdi-tray-arrow-down
              </v-icon>
              {{ documento.nombre }}
            </v-chip>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogCorreo = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SEND EMAIL -->
    <v-row justify="center">
      <v-dialog v-model="newCorreoDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Crear Correo</span>
          </v-card-title>
          <div style="padding:40px;">
            <EmailForm :usuario="usuario.id" :correoAgente="usuario.correo" />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="newCorreoDialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- ADD DOCUMENTS -->
    <v-row justify="center">
      <v-dialog v-model="documentsDialog" max-width="750px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Creación de Documento / Nota</span>
          </v-card-title>

          <div style="padding: 30px">
            <DocumentosNotasModal
              :accionGuardar="accionGuardar"
              :usuario="usuario.id"
            />
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="documentsDialog = false">
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="(accionGuardar = true), (documentsDialog = false)"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import EmailForm from "../../../components/EmailForm.vue";
import DocumentosNotasModal from "../../../components/DocumentosNotasModal.vue";
import { mapState } from 'vuex';

export default {
  components: {
    EmailForm,
    DocumentosNotasModal,
  },
  data() {
    return {
      newCorreoDialog: false,
      dialogCorreo: false,
      correoOpen: {},
      documentsDialog: false,
      dialogDetalleDocumentos: false,
      detallesDocumento: {},
      accionGuardar: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: [],
      rolElegido: null,
      guardando: false,
      usuario: {
        id: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        curp: null,
        password: null,
        confirmPassword: null,
        sexo: null,
        rol: null,
        nacimientoEstadoId: null,
        celular: null,
        telefono: null,
        status: null,
        motivoBaja: null,
        ramo: null,
        canal: null,
        landing: null,
        vip: false,
      },
      usuarioAgente: {
        fuenteIngreso: null,
        regimen: null,
        asociadoPromotoria: null,
        tipoAgente: null,
        razonSocial: null,
        clabeBancaria: null,
        claveAgente: null,
        tipoCedula: null,
        nivelAgente: null,
        numeroCartera: null,
        cedula: null,
        rc: null,
        vigenciaCedula: null,
        vigenciaPolizaRC: null,
      },
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      correo: "",
      celular: "",
      telefono: "",
      fechaNacimiento: "",
      sexo: null,
      rfc: "",
      curp: "",
      nacionalidad: 0,
      nacimientoEstadoId: null,
      estadoCivil: "",
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      creadoPor: null,
      fechaCreacion: null,
      modificadoPor: null,
      fechaModificacion: null,
      checkbox: false,
      uuidCambio: this.$route.params.uuid,
      password: "",
      oldPassword: "",
      newPassword: "",
      passwordRules: "",
      mensajeCotrasena: "",
      dialog2: false,
      actualizadoModal: false,
      actualizadoPasswordModal: false,
      generalRules: [(v) => !!v || "Campo es necesario"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      rfcRules: [
        (v) => !!v || "RFC es necesario",
        (v) =>
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      curpRules: [
        (v) => !!v || "CURP es necesario",
        (v) =>
          /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(
            v
          ) || "CURP no es valido recordar escribir en mayusculas",
      ],
      columnsEmails: [
        {
          text: "Asunto",
          align: "start",
          sortable: false,
          value: "asunto",
        },
        { text: "Fecha", value: "fecha" },
        { text: "Para", value: "remitente" },
      ],
      columnsDocumentos: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Tipo", value: "tipo" },
        { text: "Descripción", value: "descripcion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Fecha", value: "fecha" },
        { text: "Ver", value: "url" },
      ],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocio" },
      ],
      status: [
        { id: 0, name: "Nuevo" },
        { id: 1, name: "Activo" },
        { id: 2, name: "Inactivo" },
        { id: -1, name: "Baja" },
      ],
      fuenteIngresoItems: [
        "Referido",
        "LinkedIn",
        "Facebook",
        "RH",
        "IECA",
        "Base de Datos",
        "Simulador",
        "Eventos",
      ],
      regimenItems: ["Física", "Moral"],
      landings: [
        { id: 1, name: "AIG" },
        { id: 2, name: "ANA" },
        { id: 3, name: "AXA" },
        { id: 4, name: "COMPARADOR" },
        { id: 5, name: "COMPARADOR-ADT" },
        { id: 6, name: "COMPARADOR-AUTOCOSMOS" },
        { id: 7, name: "GNP" },
        { id: 8, name: "HDI" },
        { id: 9, name: "PROVEEDORES" },
        { id: 10, name: "QUÁLITAS" },
        { id: 11, name: "SGMM SEGURIFY" },
        { id: 12, name: "SURA" },
        { id: 13, name: "SURA MOVILIDAD" },
        { id: 14, name: "SURA SGMM" },
        { id: 15, name: "AUTOS RASTREATOR AIG" },
        { id: 16, name: "TUSEGURO.COM" },
        { id: 17, name: "CORU.COM" },
      ],
      asociadoPromotoriaItems: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA Seguros" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero Seguros" },
        { id: 5, name: "Quálitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "RSA Seguros" },
        { id: 8, name: "AIG Seguros México" },
        { id: 9, name: "HDI Seguros" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "BANORTE" },
        { id: 13, name: "ATLAS" },
        { id: 14, name: "CHUBB" },
        { id: 15, name: "GMX" },
        { id: 16, name: "ALLIANZ" },
        { id: 17, name: "ARGOS" },
        { id: 18, name: "ASERTA" },
        { id: 19, name: "BX+" },
        { id: 20, name: "MUTUUS" },
        { id: 21, name: "PLAN SEGURO" },
        { id: 22, name: "PREVEM" },
        { id: 23, name: "SISNOVA" },
        { id: 24, name: "BUPA" },
      ],
      tipoAgenteItems: [
        "Agente",
        "Comisionista",
        "Agente Apoderado",
        "Agente Promotoria",
      ],
      tipoCedulaItems: ["A", "A1", "B", "B1", "C", "F"],
      nivelAgenteItems: ["Básico", "Intermedio", "Avanzado"],
      tipoItems: ["Nota", "Documento"],
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Contrato firmado del Agente",
        "Cotización",
        "Cédula del Agente",
        "Identificación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      canalList: [],
      ramoList: [],
      campaniaList: [],
      documentos: [],
      correos: [],
    };
  },

  computed: {
    currentRol() {
      let roles = [];
      let CurrentRoles = JSON.parse(localStorage.getItem("roles"));
      CurrentRoles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
    canEdit() {
      let roles = [];
      let rolesStorage = JSON.parse(localStorage.getItem("roles"));
      rolesStorage.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });
      var canview = false;
      if (roles.length > 0) {
        var rol = roles[0];
        if (rol == "ADMIN" || rol == "ADMINCC" || rol == "MANAGERCC") {
          canview = true;
        }
      } else {
        canview = false;
      }
      if (typeof this.$route.params.uuid == "undefined") {
        canview = true;
      }

      return canview;
    },
    ...mapState('permisos', ['permisos']),
    canEdicion(){
      return (this.permisos && 
      (this.permisos.includes('FUN_ADMINISTRACION_DE_USUARIOS_ADMINCUENTA') || 
      this.permisos.includes('FUN_ADMINISTRACION_DE_USUARIOS_COORDINADOR') ||
      this.permisos.includes('FUN_ADMINISTRACION_DE_USUARIOS_GERENTE') ||
      this.permisos.includes('FUN_ADMINISTRACION_DE_USUARIOS_SUPERVISOR') ||
      this.permisos.includes('FUN_ADMINISTRACION_DE_USUARIOS_ADMIN')
      ));
    },
  },

  mounted() {
    this.getRoles();
    this.listas();
    this.getUser();
  },

  methods: {
    getRoles() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      this.$axios.get("/v1/agente/roles/list", config).then(({ data }) => {
        this.roles = data.map((e) => ({
          id: e.rol,
          nombre: e.nombre,
        }));
      });
    },

    verDetalleCorreo(correo) {
      console.log("Correo open:", correo);
      this.dialogCorreo = true;
      this.correoOpen = correo;
    },
    verDocumento(documento) {
      console.log("Documento open: ", documento);
      this.dialogDetalleDocumentos = true;
      this.detallesDocumento = documento;
    },
    mostrarInputsAgente() {
      if (this.usuario.rol != "AGENT") {
        return false;
      } else return true;
    },
    goBack() {
      return this.$router.go(-1);
    },
    verCuentaEditable() {
      if (this.$route.params.uuid) {
        this.$router.push(`/usuario/agente/${this.$route.params.uuid}`);
      } else {
        this.$router.push("/usuario/micuenta");
      }
    },
    getUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let uuid = this.$route.params.uuid;

      if (this.$route.params.uuid) {
        this.$axios.get(`/v1/agente/uuid/${uuid}`, config).then((response) => {
          console.log("Response: ", response.data);
          response.data.documentosCarga
            ? response.data.documentosCarga.map((element) => {
                this.documentos.push({
                  nombre: element.nombre
                    ? element.nombre
                    : "Solicitud #" + element.solicitudId,
                  fecha: element.fecha
                    ? moment
                        .utc(element.fecha)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY HH:mm:ss")
                    : "No Disponible",
                  tipo: element.tipo ? element.tipo : "No Disponible",
                  tipoDocumento: element.tipoDocumento
                    ? element.tipoDocumento
                    : "No Disponible",
                  descripcion: element.nota
                    ? element.nota
                    : "Solicitud #" + element.solicitudId,
                  creadoPor: element.creadoPor
                    ? element.creadoPor
                    : "No Disponible",
                  url: element.url,
                });
              })
            : "";
          response.data.correos
            ? response.data.correos.map((element) => {
                this.correos.push({
                  asunto: element.subject,
                  fecha: element.fecha
                    ? moment
                        .utc(element.fecha)
                        .subtract(6, "hours")
                        .format("DD/MM/YYYY HH:mm")
                    : null,
                  cuerpo: element.cuerpo,
                  remitente: element.remitente,
                });
              })
            : "";

          this.usuario.id = response.data.id ? response.data.id : null;
          this.usuario.correo = response.data.username;
          this.usuario.nombre = response.data.nombre;
          this.usuario.apellidoPaterno = response.data.apellidoPaterno;
          this.usuario.apellidoMaterno = response.data.apellidoMaterno;
          this.usuario.celular = response.data.celular;
          this.usuario.telefono = response.data.telefono;
          this.usuario.fechaNacimiento = moment(
            response.data.fechaNacimiento,
            "YYYY-MM-DD"
          ).format("DD/MM/YYYY");
          this.usuario.fechaRegistro = moment
            .utc(response.data.fechaRegistro)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm");
          this.usuario.sexo = response.data.sexo;
          this.usuario.nacimientoEstadoId = response.data.nacimientoEstadoId;
          this.usuario.rfc = response.data.rfc;
          this.usuario.curp = response.data.curp;
          this.usuario.password = response.data.password;
          this.usuario.status = response.data.status;
          let rolesBase =
            response.data.roles.length > 0 ? response.data.roles : [];
          let roles = [];
          rolesBase.forEach((element) => {
            if (element.rol != null) {
              roles.push(element.rol);
            }
          });
          if (roles.length > 0) {
            this.usuario.rol = roles[0];
          } else {
            this.usuario.rol = "";
          }
          this.usuario.ramo = response.data.ramos;
          this.usuario.canal = response.data.canales;
          this.usuario.landing = response.data.landings;
          this.usuarioAgente.fuenteIngreso = response.data.fuenteIngreso
            ? response.data.fuenteIngreso
            : null;
          this.usuarioAgente.regimen = response.data.regimen
            ? response.data.regimen
            : null;
          this.usuarioAgente.asociadoPromotoria = response.data
            .agenteAsociadoPromotoria
            ? response.data.agenteAsociadoPromotoria
            : null;
          this.usuarioAgente.tipoAgente = response.data.tipoAgente
            ? response.data.tipoAgente
            : null;
          this.usuarioAgente.razonSocial = response.data.razonSocial
            ? response.data.razonSocial
            : null;
          this.usuarioAgente.clabeBancaria = response.data.clabeBancaria
            ? response.data.clabeBancaria
            : null;
          this.usuarioAgente.claveAgente = response.data.claveAgente
            ? response.data.claveAgente
            : null;
          this.usuarioAgente.tipoCedula = response.data.tipoCedula
            ? response.data.tipoCedula
            : null;
          this.usuarioAgente.nivelAgente = response.data.nivelAgente
            ? response.data.nivelAgente
            : null;
          this.usuarioAgente.numeroCartera = response.data.numeroCartera
            ? response.data.numeroCartera
            : null;
          this.usuarioAgente.cedula = response.data.cedula
            ? response.data.cedula
            : null;
          this.usuarioAgente.rc = response.data.rc ? response.data.rc : null;
          this.creadoPor = response.data.creadoPor
            ? response.data.creadoPor
            : null;
          this.fechaCreacion = response.data.fechaRegistro
            ? moment
                .utc(response.data.fechaRegistro)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : null;
          this.modificadoPor = response.data.modificadoPor
            ? response.data.modificadoPor
            : null;
          this.fechaModificacion = response.data.ultimaModificacion
            ? moment
                .utc(response.data.ultimaModificacion)
                .subtract(6, "hours")
                .format("DD/MM/YYYY HH:mm:ss")
            : null;
          this.usuarioAgente.vigenciaCedula = response.data.vigenciaCedula
            ? moment(response.data.vigenciaCedula, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )
            : null;
          this.usuarioAgente.vigenciaPolizaRC = response.data.vigenciaPolizaRc
            ? moment(response.data.vigenciaPolizaRc, "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              )
            : null;
        });
      } else {
        this.$axios
          .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
          .then((response) => {
            console.log("Response: ", response.data);
            let rolBase =
              response.data.roles.length == 0
                ? "AGENT"
                : response.data.roles[0].rol;

            response.data.documentosCarga
              ? response.data.documentosCarga.map((element) => {
                  this.documentos.push({
                    nombre: element.nombre
                      ? element.nombre
                      : "Solicitud #" + element.solicitudId,
                    fecha: element.fecha
                      ? moment
                          .utc(element.fecha)
                          .subtract(6, "hours")
                          .format("DD/MM/YYYY HH:mm:ss")
                      : "No Disponible",
                    tipo: element.tipo ? element.tipo : "No Disponible",
                    tipoDocumento: element.tipoDocumento
                      ? element.tipoDocumento
                      : "No Disponible",
                    descripcion: element.nota
                      ? element.nota
                      : "Solicitud #" + element.solicitudId,
                    creadoPor: element.creadoPor
                      ? element.creadoPor
                      : "No Disponible",
                    url: element.url,
                  });
                })
              : "";
            response.data.correos
              ? response.data.correos.map((element) => {
                  this.correos.push({
                    asunto: element.cuerpo,
                    fecha: element.fecha
                      ? moment
                          .utc(element.fecha)
                          .subtract(6, "hours")
                          .format("DD/MM/YYYY HH:mm")
                      : null,
                    cuerpo: element.cuerpo,
                    remitente: element.remitente,
                  });
                })
              : "";

            (this.usuario.id = response.data.id ? response.data.id : null),
              (this.usuario.correo = response.data.username);
            this.usuario.nombre = response.data.nombre;
            this.usuario.apellidoPaterno = response.data.apellidoPaterno;
            this.usuario.apellidoMaterno = response.data.apellidoMaterno;
            this.usuario.celular = response.data.celular;
            this.usuario.telefono = response.data.telefono;
            this.usuario.fechaNacimiento = moment(
              response.data.fechaNacimiento,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY");
            this.usuario.fechaRegistro = moment
              .utc(response.data.fechaRegistro)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm");
            this.usuario.sexo = response.data.sexo;
            this.usuario.nacimientoEstadoId = response.data.nacimientoEstadoId;
            this.usuario.rfc = response.data.rfc;
            this.usuario.curp = response.data.curp;
            this.usuario.password = response.data.password;
            this.usuario.status = response.data.status;
            this.usuario.rol = rolBase;
            this.usuario.ramo = response.data.ramos;
            this.usuario.canal = response.data.canales;
            this.usuario.landing = response.data.landings;
            this.usuarioAgente.fuenteIngreso = response.data.fuenteIngreso
              ? response.data.fuenteIngreso
              : null;
            this.usuarioAgente.regimen = response.data.regimen
              ? response.data.regimen
              : null;
            this.usuarioAgente.asociadoPromotoria = response.data
              .agenteAsociadoPromotoria
              ? response.data.agenteAsociadoPromotoria
              : null;
            this.usuarioAgente.tipoAgente = response.data.tipoAgente
              ? response.data.tipoAgente
              : null;
            this.usuarioAgente.razonSocial = response.data.razonSocial
              ? response.data.razonSocial
              : null;
            this.usuarioAgente.clabeBancaria = response.data.clabeBancaria
              ? response.data.clabeBancaria
              : null;
            this.usuarioAgente.claveAgente = response.data.claveAgente
              ? response.data.claveAgente
              : null;
            this.usuarioAgente.tipoCedula = response.data.tipoCedula
              ? response.data.tipoCedula
              : null;
            this.usuarioAgente.nivelAgente = response.data.nivelAgente
              ? response.data.nivelAgente
              : null;
            this.usuarioAgente.numeroCartera = response.data.numeroCartera
              ? response.data.numeroCartera
              : null;
            this.usuarioAgente.cedula = response.data.cedula
              ? response.data.cedula
              : null;
            this.usuarioAgente.rc = response.data.rc ? response.data.rc : null;
            this.usuarioAgente.vigenciaCedula = response.data.vigenciaCedula
              ? moment(response.data.vigenciaCedula, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : null;
            this.usuarioAgente.vigenciaPolizaRC = response.data.vigenciaPolizaRc
              ? moment(response.data.vigenciaPolizaRc, "YYYY-MM-DD").format(
                  "DD/MM/YYYY"
                )
              : null;
          });
      }
    },

    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.$axios.get(`/v1/canal/list`, config).then((response) => {
        this.canalList = response.data;
      });
      this.$axios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramoList = response.data;
      });
      this.$axios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });
    },
  },
};
</script>
